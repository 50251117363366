import React from 'react';

const EmptyTree = () => {
  return (
    <div className='alert alert-danger'>
      <p>Ei tuloksia</p>
    </div>
  );
};

export default EmptyTree;
